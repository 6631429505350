import React, { useEffect, useState } from "react";
import ContentTitle from "../components/ContentTitle";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { ThreeDots } from "react-loader-spinner";

const Events = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [events, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem('lang');
      const formData = new FormData();
      formData.append("school_id", sessionData?.school_id);
      formData.append('lang', language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(`${apiUrl}/events.json`, requestOptions);
        const data = await response.json();
        setEvent(data?.gallery_details);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [apiUrl]);

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="Events" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Events
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>

          <div className="table-container">
            <div className="table-responsive">
              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                events?.map((val, index) => {
                  // Split the string into an array
                  const imageArray = val?.images?.split(",");
                  console.log(imageArray, "imageArray");
                  return (
                    <div className="event-container" key={index}>
                      <div className="content_card_title flex-view">
                        <h3>{val?.title}</h3>
                        <span></span>
                      </div>
                      <h4 style={{ marginTop: "0", paddingLeft: "20px" }}>
                        Description:
                        <span style={{ fontWeight: "lighter" }}>
                          {val?.description}
                        </span>
                      </h4>
                      <div className="container-of-images">
                        {imageArray.length > 0 ? (
                          imageArray.map((img, i) => (
                            <div className="image-container">
                              <img
                                src={`https://you-me-globaleducation.org/school/webroot/img/${img}`}
                                alt={`Event image ${i + 1}`} // Provide alt text for accessibility
                                key={i}
                              />
                            </div>
                          ))
                        ) : (
                          <p>No images available</p> // Handle the case where there are no images
                        )}
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
