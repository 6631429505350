import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import "../css/style.css";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import CryptoJS from "crypto-js";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { useMessages } from "../context/MessageContext";

const SubjectGrades = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const reactApiUrl = process.env.REACT_APP_API_MAIN_URL;
  const nodeApiUrl = process.env.REACT_APP_API_NODE_URL;
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const { sutudentSub } = useParams();
  const [subId, setSubId] = useState(null);
  const [gradeData, setGradeData] = useState([]);
  const studentData = JSON.parse(localStorage.getItem("student"));
  const [showModal, setShowModal] = useState(false);
  const { showSuccess, showError, clearMessages } = useMessages();
  const apiUrlMain = process.env.REACT_APP_API_MAIN_URL;
  const [claimDescription, setClaimDescription] = useState("");
  const { studentId } = useParams();
  const [examId, setExamId] = useState("");
  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sutudentSub]);

  // all subjects api
  useEffect(() => {
    console.log(sutudentSub, "sutudentSub");
    const getSubjects = async () => {
      const studentData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${nodeApiUrl}/subject/subjects?school_id=${studentData?.school_id}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        const getSubFilter = await result?.filter((e) => {
          return e?.subject_name === sutudentSub;
        });
        setSubId(getSubFilter[0]?.id);
      } catch (error) {
        console.error(error);
      }
    };

    getSubjects();
  }, [nodeApiUrl, reactApiUrl, sutudentSub]);

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  //   setCurrentPage(1);

  //   const filteredData = originalData.flat().filter(
  //     (item) =>
  //       (item.title?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
  //       (item.type?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
  //       (item.exam_type?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
  //       (item.exam_period?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
  //       (item.marks?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
  //       (item.grade?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  //   );

  //   const reNestedData = [];
  //   const filteredIds = new Set(filteredData.map((item) => item.id)); // Track IDs of filtered items

  //   originalData.forEach((subArray) => {
  //     const filteredSubArray = subArray.filter((item) =>
  //       filteredIds.has(item.id)
  //     );
  //     if (filteredSubArray.length > 0) {
  //       reNestedData.push(filteredSubArray);
  //     }
  //   });

  //   setGradeData(reNestedData);
  // };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleShowModal = (exam) => {
    setExamId(exam);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const Loader = () => (
    <div className="loader">
      <div className="spinner"></div> {/* This can be any loader design */}
    </div>
  );

  const submitQuize = async () => {
    setLoading(true);
    const authToken = "vWEMNrj5R8aKwZYYZoxcUMDWk5OnHf";
    const language = localStorage.getItem("lang");
    const formData = new FormData();
    formData.append("class_id", studentData?.class);
    formData.append("subject_id", studentId);
    formData.append("claim", claimDescription);
    formData.append("sub_exam_id", examId);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      const response = await fetch(
        `${apiUrlMain}/updateclaim.json`,
        requestOptions
      );
      const result = await response.json();
      if (response.ok) {
        showSuccess(`${result?.Message}: Exam Uploaded`);
        // window.location.reload();
      } else {
        showError(result.message);
      }

      handleCloseModal();
    } catch (error) {
      console.error(error);
      showError(error);
    } finally {
      setLoading(false);
      setTimeout(() => {
        clearMessages();
      }, 3000);
    }
  };

  // grade api
  useEffect(() => {
    const gradList = async () => {
      setLoading(true);

      const numberString = studentData?.id.toString();
      const md5Hash = CryptoJS.MD5(numberString).toString();
      const offset = (currentPage - 1) * limit;
      const language = localStorage.getItem("lang");

      const formData = new FormData();
      formData.append("class_id", studentData?.class);
      formData.append("subjectid", studentId);
      formData.append("studentid", md5Hash);
      formData.append("offset", offset);
      formData.append("limit", limit);
      formData.append("sessionid", studentData?.sessionId);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${reactApiUrl}/subjectgradesdata.json`,
          requestOptions
        );
        const result = await response.json();
        // const filteredData = result?.exams_sts_dtl?.filter(
        //   (item) =>
        //     item[0]?.grade !== null ||
        //     item[0]?.marks !== null ||
        //     item[0]?.upload_exams !== null
        // );

        if (searchTerm !== "") {
          const fil = await result?.exams_sts_dtl.filter((item) => {
            // return item[0]?.type == searchTerm
            return (
              (item[0]?.title?.toLowerCase() || "").includes(
                searchTerm.toLowerCase()
              ) ||
              (item[0]?.type?.toLowerCase() || "").includes(
                searchTerm.toLowerCase()
              ) ||
              (item[0]?.exam_type?.toLowerCase() || "").includes(
                searchTerm.toLowerCase()
              ) ||
              (item[0]?.exam_period?.toLowerCase() || "").includes(
                searchTerm.toLowerCase()
              ) ||
              (item[0]?.marks?.toLowerCase() || "").includes(
                searchTerm.toLowerCase()
              ) ||
              (item[0]?.grade?.toLowerCase() || "").includes(
                searchTerm.toLowerCase()
              )
            );
          });
          setGradeData(fil);
          setOriginalData(fil);
          console.log(fil, "fil claim_status");
        } else {
          setGradeData(result?.exams_sts_dtl);
          setOriginalData(result?.exams_sts_dtl);
        }

        setTotalPages(Math.ceil(result.total_records / limit));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    gradList();
  }, [reactApiUrl, limit, currentPage, subId, searchTerm]);

  // useEffect(() => {
  //   if (originalData.length > 0) {
  //     handleSearchChange({ target: { value: searchTerm } });
  //   }
  // }, [originalData]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="All Grades" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Student Profile &gt; {sutudentSub?.split("_").join(" ")}
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="row clearfix" style={{ padding: "0px 20px" }}>
            <div className="col-lg-12">
              <div className="card">
                <div className="entries-and-search-container">
                  <div className="entries">
                    Show
                    <select
                      className="form-control"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    entries
                  </div>
                  <div className="entries">
                    Search:
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={searchTerm}
                      onChange={(e) => handleSearchChange(e)}
                    />
                  </div>
                </div>
                <div className="table-container">
                  <div className="table-responsive">
                    {loading ? (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#8e008e"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass=""
                      />
                    ) : (
                      // <p>Loading...</p>
                      <table
                        className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                        id="notification_table"
                        data-page-length="50"
                        role="grid"
                        aria-describedby="notification_table_info"
                      >
                        <thead className="thead-dark">
                          <tr role="row">
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Type: activate to sort column ascending"
                            >
                              Type
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Title: activate to sort column ascending"
                            >
                              Title
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Request Type: activate to sort column ascending"
                            >
                              Request Type
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Request Period: activate to sort column ascending"
                            >
                              Request Period
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Submission Date: activate to sort column ascending"
                            >
                              Submission Date
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Marks: activate to sort column ascending"
                            >
                              Marks
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Grades: activate to sort column ascending"
                            >
                              Grades
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Submitted Exam/Assignment Type: activate to sort column ascending"
                            >
                              Submitted Exam/Assignment
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Evaluate Exam/Assignment : activate to sort column ascending"
                            >
                              Evaluate Exam/Assignment
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Raise Claim (If any) : activate to sort column ascending"
                            >
                              Raise Claim (If any)
                            </th>
                          </tr>
                        </thead>
                        <tbody id="notificationbody">
                          {gradeData?.map((grade) => {
                            console.log(grade, "gradeeeeeeeeeeeeeeeeee");
                            return grade?.map((val, index) => {
                              return (
                                <>
                                  <tr role="row" className="odd" key={index}>
                                    <td>{val?.type}</td>
                                    <td>{val?.title}</td>
                                    <td>{val?.exam_type}</td>
                                    <td>{val?.exam_period}</td>
                                    <td>
                                      {val?.end_date
                                        ? moment(
                                            val?.end_date,
                                            "DD-MM-YYYY"
                                          ).format("MMM D, YYYY hh:mm A")
                                        : "-"}
                                    </td>
                                    <td>{val?.marks}</td>
                                    <td>{val?.grade}</td>
                                    <td>
                                      {val?.status == 1 ? (
                                        <Link
                                          to={`/dashboard/pdf/${sutudentSub}/${studentId}/${val?.exam_id}`}
                                          // /dashboard/pdf/${sutudentSub}/${studentId}/${quiz?.id}
                                          style={{
                                            cursor: "pointer",
                                            background: "#8e008e",
                                            color: "white",
                                            border: "none",
                                          }}
                                          className="bi bi-file-earmark-text"
                                          target="_blank"
                                        ></Link>
                                      ) : (
                                        <Link
                                          // to={`/dashboard/pdf/${sutudentSub}/${studentId}/${val?.exam_id}`}
                                          style={{
                                            cursor: "pointer",
                                            background: "#8e008e",
                                            color: "white",
                                            border: "none",
                                          }}
                                          className="bi bi-file-earmark-text"
                                        ></Link>
                                      )}
                                    </td>
                                    <td>-</td>
                                    <td>
                                      {val?.claim_status == !null ? (
                                        <Link
                                          // to={`/dashboard/subject/exams/view_Exam/info/${studentId}/${quiz?.id}`}
                                          style={{
                                            cursor: "pointer",
                                            background: "#8e008e",
                                            color: "white",
                                            border: "none",
                                          }}
                                          className="instruction-btn"
                                        >
                                          Claim Raised
                                        </Link>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            handleShowModal(val.exam_id)
                                          }
                                          className="btn btn-sm btn-outline-secondary"
                                          style={{
                                            cursor: "pointer",
                                            background: "#8e008e",
                                            color: "white",
                                            border: "none",
                                          }}
                                        >
                                          Raise Claim (if any)
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            });
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>

                <div className="pagination-container">
                  <p>
                    Show 1 to {limit} of {gradeData?.length} entries
                  </p>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${showModal ? "transformOn" : "transformOff"}`}
          style={{ maxWidth: "500px", minWidth: "250px" }}
        >
          <div className="custom-modal-title">
            Raise Claim (If any)
            <i
              className="bi bi-x-lg"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          {loading && <Loader />}
          {!loading && (
            <>
              <form onSubmit={submitQuize}>
                <div className="input-container-width-label">
                  <label>Claim</label>
                  <textarea
                    name="claim"
                    rows="1"
                    cols="10"
                    placeholder="Claim"
                    value={claimDescription}
                    onChange={(e) => setClaimDescription(e.target.value)}
                    required
                  ></textarea>
                </div>
                {/* btn-row */}

                <div className="btn-container">
                  <button type="submit" className="back-btn">
                    Send
                  </button>
                  <span style={{ margin: "5px" }}></span>
                  <button className="back-btn" onClick={handleCloseModal}>
                    Close
                  </button>
                </div>
              </form>
              {/* <h5>Raise Claim</h5>
              <div>
                <textarea
                  name="claim"
                  rows="1"
                  cols="10"
                  placeholder="Claim"
                  value={claimDescription}
                  onChange={(e) => setClaimDescription(e.target.value)}
                ></textarea>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button className="back-btn" onClick={submitQuize}>
                  Send
                </button>
                <span style={{ margin: "5px" }}></span>
                <button className="back-btn" onClick={handleCloseModal}>
                  Close
                </button>
              </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubjectGrades;
