import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";

const ViewCart = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { date, time } = useParams();
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [count, setCount] = useState(0);
  const [modalOoen, setModalOpen] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [removeItems, setRemoveItem] = useState(false);
  const [remark, setRemark] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [pinModal, setPinModal] = useState(false);
  const [pin, setPin] = useState("");
  const [indexCount, setIndexCount] = useState([]);
  const [removeItemId, setREmoveItemId] = useState("");
  const [removeItemMessageModal, setREmoveItemMessageModal] = useState(false);
  const [removeMessage, setRemoveMessage] = useState("");

  const convertDateToTimestamp = (dateString) => {
    // Create a new Date object in UTC with a specific time (e.g., 9 AM UTC)
    const date = new Date(dateString + "T09:00:00Z");

    // Convert the date to a timestamp (in seconds)
    const timestamp = Math.floor(date.getTime() / 1000);

    return timestamp;
  };

  //view cart data api
  const getCartData = async () => {
    setLoading(true);
    const language = localStorage.getItem("lang");
    const studentData = JSON.parse(localStorage.getItem("student"));
    const timestamp = convertDateToTimestamp(date);
    const formData = new FormData();
    formData.append("student_id", studentData?.id);
    formData.append("session_id", studentData?.session_id);
    formData.append("school_id", studentData?.school_id);
    formData.append("class_id", studentData?.class);
    formData.append("stime", time);
    formData.append("sdate", timestamp);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(`${apiUrl}/viewcart.json`, requestOptions);
      const result = await response.json();
      setViewData(result?.cartdata);
      const totalQuantity = result?.cartdata?.reduce(
        (acc, item) => acc + (item?.quantity || 0),
        0
      );
      setCount(totalQuantity); // Set total quantity
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCartData();
  }, [apiUrl]);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  //   add to cart api
  const addItems = async (index, val) => {
    setIndexCount((prevCounts) => {
      const updatedCounts = [...prevCounts];
      updatedCounts[index] = val?.quantity + 1;
      return updatedCounts;
    });

    const language = localStorage.getItem("lang");
    const studentData = JSON.parse(localStorage.getItem("student"));
    const timestamp = convertDateToTimestamp(date);
    const formData = new FormData();
    formData.append("student_id", studentData?.id);
    formData.append("session_id", studentData?.session_id);
    formData.append("school_id", studentData?.school_id);
    formData.append("class_id", studentData?.class);
    formData.append("food_id", val?.food_id);
    formData.append("vendor_id", val?.vendor_id);
    formData.append("fp", val?.price);
    formData.append("qnty", val?.quantity + 1);
    formData.append("stime", time);
    formData.append("sdate", timestamp);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(`${apiUrl}/cartdata.json`, requestOptions);
      const result = await response.json();
      getCartData();
    } catch (error) {
      console.error(error);
    }
  };

  //   remove to cart api
  const removeItemsQuantity = async (index, val) => {
    setIndexCount((prevCounts) => {
      const updatedCounts = [...prevCounts];
      updatedCounts[index] = val?.quantity - 1;
      return updatedCounts;
    });

    const language = localStorage.getItem("lang");
    const studentData = JSON.parse(localStorage.getItem("student"));
    const timestamp = convertDateToTimestamp(date);
    const formData = new FormData();
    formData.append("student_id", studentData?.id);
    formData.append("session_id", studentData?.session_id);
    formData.append("school_id", studentData?.school_id);
    formData.append("class_id", studentData?.class);
    formData.append("food_id", val?.food_id);
    formData.append("vendor_id", val?.vendor_id);
    formData.append("fp", val?.price);
    formData.append("qnty", val?.quantity - 1);
    formData.append("stime", time);
    formData.append("sdate", timestamp);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(`${apiUrl}/cartdata.json`, requestOptions);
      const result = await response.json();
      getCartData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenRemoveModal = (itemID) => {
    setRemoveItem(true);
    setREmoveItemId(itemID);
  };

  //   remove cart api
  const removeItem = async () => {
    const language = localStorage.getItem("lang");
    const requestOptions = {
      method: "GET",
    };
    try {
      const response = await fetch(
        `${apiUrl}/removecart.json?id=${removeItemId}&lang=${language}`,
        requestOptions
      );
      const result = await response.json();
      if (result?.message === "Item removed from cart") {
        setRemoveItem(false);
        setREmoveItemMessageModal(true);
        setRemoveMessage(result?.message);
        getCartData()
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setREmoveItemMessageModal(false);
        setRemoveMessage("");
      }, 3000);
    }
  };

  // place order api
  const placeOrder = async (event) => {
    event.preventDefault();
    setPinModal(true);
    const language = localStorage.getItem("lang");
    const studentData = JSON.parse(localStorage.getItem("student"));
    const timestamp = convertDateToTimestamp(date);
    const formData = new FormData();
    formData.append("student_id", studentData?.id);
    formData.append("session_id", studentData?.session_id);
    formData.append("class_id", studentData?.class);
    formData.append("remark", remark);
    formData.append("stime", time);
    formData.append("sdate", timestamp);
    formData.append("school_id", studentData?.school_id);
    formData.append("canteenpin", pin);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(`${apiUrl}/placeorder.json`, requestOptions);
      const result = await response.json();
      setPinModal(false);
      setPin("");
      setShowModal(true);
      setModalMessage(result?.message);
    } catch (error) {
      console.error(error);
      setShowModal(true);
      setModalMessage(error?.message);
    } finally {
      setPinModal(false);
    }
  };

  const handleClosePlaceOrderMessage = () => {
    setShowModal(false);
    setModalMessage("");
  };

  const handleClosePinModal = () => {
    setPin("");
    setPinModal(false);
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />

      <div className={`main containerFull`}>
        <ContentTitle name="Canteen" click={sidebarOpen} />
        <div className="card_board">
          <div
            className="content_card_title flex-view"
            style={{
              fontWeight: "lighter",
              fontSize: "25px",
              color: "rgb(75, 73, 73)",
            }}
          >
            View Cart
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              <b>
                Date: {date} Timings: {time}
              </b>
            </div>
            <div className="selectboxes">
              <button
                className="contact-us-btn"
                onClick={() => navigate("/dashboard")}
              >
                Back To Dashboard
              </button>
              <span
                style={{ marginRight: "10px", marginBottom: "10px" }}
              ></span>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <div style={{ padding: "0px 20px" }}>
              <h2
                style={{
                  fontWeight: "lighter",
                  fontSize: "20px",
                  color: "rgb(75, 73, 73)",
                }}
              >
                Food List
              </h2>
              <div className="food-item-wrapper">
                {viewData?.map((item, index) => {
                  return (
                    <div className="menu-item-container" key={index}>
                      <div className="img-container">
                        <img
                          src={`https://you-me-globaleducation.org/school/c_food/${item?.food_item?.food_img}`}
                          alt={item?.food_item?.food_img}
                        />
                      </div>
                      <div className="content-container">
                        <div className="text-container">
                          <div>
                            <h4>{item?.canteen_vendor?.vendor_company}</h4>
                            <h4>{item?.food_item?.food_name}</h4>
                            <h4>Item Price: ${item?.price}</h4>
                            <h4>
                              Total Price: ${item?.price * item?.quantity}
                            </h4>
                          </div>
                          <i
                            className="bi bi-trash"
                            onClick={() => handleOpenRemoveModal(item?.id)}
                            style={{ border: "1px solid red", color: "red" }}
                          ></i>
                        </div>
                        <div className="btn-container">
                          <>
                            <button
                              className="btn-2"
                              style={{
                                width: "80px",
                                justifyContent: "center",
                              }}
                            >
                              <i
                                className="bi bi-cart4"
                                style={{ marginRight: "0" }}
                              ></i>
                            </button>
                            <div className="quantity-wrapper">
                              <span
                                onClick={() => removeItemsQuantity(index, item)}
                              >
                                -
                              </span>
                              {item?.quantity}
                              <span onClick={() => addItems(index, item)}>
                                +
                              </span>
                            </div>
                          </>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="remarts-container">
                <label>Remarks(If any allergies)*:</label>
                <textarea
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />

                <p>
                  Mise en garde: “Nous utilisons actuellement différents
                  produits alimentaires qui peuvent provoquer des réactions
                  allergiques. Nous conseillons donc à notre précieuse clientèle
                  de bien vouloir nous informer de toute allergie ou préférences
                  alimentaires, le cas échéant, lors de votre commande. Nous
                  déclinons toutes les responsabilités pouvant résulter de la
                  consommation de nos aliments contenant des traces éventuelles
                  de gluten, de fruits à coque, de soja, de lait et d'arachides
                  dans nos produits. Certains de nos plats peuvent contenir du
                  blé, des crustacés, poisson, des œufs, des noix et du lait ou
                  même entrer en contact avec ces aliments.”
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="greenline">
        <button className="back-btn">
          Total Balance: $-
          {viewData.reduce((acc, item) => {
            return acc + item.quantity;
          }, 0)}
        </button>
        <button className="back-btn">
          Cart Amount: $-
          {viewData.reduce((acc, item) => {
            return acc + item.price * item.quantity;
          }, 0)}
        </button>
        <button className="back-btn" onClick={() => setPinModal(true)}>
          Place Your Order
        </button>
      </div>
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${modalOoen ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box modal-announcement ${
            modalOoen ? "transformOn" : "transformOff"
          }`}
        >
          <div className="modal-title">
            Food details
            <i
              className="bi bi-x-lg"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <div className="notification-title-container">
            <span />
          </div>
        </div>
      </div>
      {/* remove item Message Modal */}
      <div
        className={`student-Modal-container`}
        style={{
          visibility: `${removeItemMessageModal ? "visible" : "hidden"}`,
        }}
      >
        <div
          className={`modal-box ${
            removeItemMessageModal ? "transformOn" : "transformOff"
          }`}
          style={{ maxWidth: "400px", minWidth: "250px" }}
        >
          <div className="meeting-modal">
            <h2 style={{ margin: "0" }}>{removeMessage}</h2>
            <div
              className="meeting-modal-btn-container"
              style={{ marginTop: "20px" }}
            >
              <button>OK</button>
            </div>
          </div>
        </div>
      </div>
      {/* remove item Modal */}
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${removeItems ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${
            removeItems ? "transformOn" : "transformOff"
          }`}
          style={{ maxWidth: "400px", minWidth: "250px" }}
        >
          <div className="meeting-modal">
            <h2 style={{ margin: "0" }}>Are you sure?</h2>
            <p
              style={{
                fontSize: "13px",
                color: "gray",
                textAlign: "center",
                margin: "0",
              }}
            >
              you want to remove the item from cart
            </p>
            <div
              className="meeting-modal-btn-container"
              style={{ marginTop: "20px" }}
            >
              <button
                onClick={() => setRemoveItem(false)}
                style={{ backgroundColor: "gray" }}
              >
                Cancel
              </button>
              <span style={{ margin: "10px" }}></span>
              <button onClick={removeItem}>Yes Remove It</button>
            </div>
          </div>
        </div>
      </div>
      {/* Custom Modal */}
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${showModal ? "transformOn" : "transformOff"}`}
          style={{ maxWidth: "400px", minWidth: "250px" }}
        >
          <div className="meeting-modal">
            <h2>{modalMessage}</h2>
            <div className="meeting-modal-btn-container">
              {" "}
              <button onClick={handleClosePlaceOrderMessage}>OK</button>
            </div>
          </div>
        </div>
      </div>

      {/* pin modal */}
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${pinModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${pinModal ? "transformOn" : "transformOff"}`}
          style={{ maxWidth: "600px", minWidth: "250px" }}
        >
          <div className="custom-modal-title">
            Verify Pin for placing order
            <i
              className="bi bi-x-lg"
              onClick={handleClosePinModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <form onSubmit={placeOrder}>
            <div className="input-container-width-label">
              <label>Place Order Pin*</label>
              <input
                type="text"
                placeholder="Order Verify Pin*"
                required
                onChange={(e) => setPin(e.target.value)}
                value={pin}
              />
            </div>
            {/* btn-row */}

            <div className="btn-container">
              <button type="submit" className="back-btn">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ViewCart;
