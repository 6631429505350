// src/admin/Dashboard.js
import React, { useEffect, useState } from "react";
import "../css/style.css"; // Import your CSS file
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import ContentTitle from "../components/ContentTitle";

const Dashboard = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [classes, setClasses] = useState([]); // State for classes
  const studentData = JSON.parse(localStorage.getItem("student"));

  useEffect(() => {
    const getClasses = async () => {
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/classlist.json?school_id=${studentData?.school_id}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        console.log(result, "result");
        const filterClass = await result?.classes?.filter((v) => {
          return v?.id == studentData?.class;
        });
        setClasses(filterClass); // Assuming your API returns an array of classes
      } catch (error) {
        console.error(error);
      }
    };

    getClasses();
  }, [apiUrl]);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };
  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <div id="main-content1" className="content_container">
          <ContentTitle name="Student Dashboard" click={sidebarOpen} />
          <div className="card_board">
            {classes.length === 1 && (
              <div
                style={{
                  textAlign: "center",
                  color: "#242E3B",
                  marginBottom: "20px",
                  fontSize: "14px",
                }}
              >
                <b>
                  {classes[0]?.c_name}-{classes[0]?.c_section}(
                  {classes[0]?.school_sections}){" "}
                </b>
              </div>
            )}
            <div className="options">
              <Link to="/dashboard/student-knowledge">
                <button className="option" data-target="grades">
                  You Me Academy
                </button>
              </Link>
              <Link to="/dashboard/student-profile">
                <button className="option" data-target="grades">
                  Student Profile
                </button>
              </Link>
              <Link to="#">
                <button className="option" data-target="subjects">
                  Student Dairy
                </button>
              </Link>
              <Link to="/dashboard/my_school">
                <button className="option" data-target="subjects">
                  My School
                </button>
              </Link>
              <Link to="#">
                <button className="option card-option" data-target="subjects">
                  Report Card
                </button>
              </Link>
              <Link to="https://learn.eltngl.com/" target="_blank">
                <button
                  className="option national"
                  data-target="subjects"
                ></button>
              </Link>
              <Link to="#" target="_blank">
                <button
                  className="option morning"
                  data-target="subjects"
                ></button>
              </Link>
              <Link to="https://educadhoc.fr/" target="_blank">
                <div>
                  <button data-target="subjects" className="hac"></button>{" "}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
