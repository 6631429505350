import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";

const StudentDiary = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [todyDate, setTodayDate] = useState("");
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [subjects, setSubjects] = useState([]);
  const [subjectTextIDs, setSubjectTextIDs] = useState([]);
  const [subjectContent, setSubjContent] = useState({});
  const [note, setNote] = useState("");
  const [textareaVal, setTextareaVal] = useState("");
  const textref = useRef("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setTodayDate(formattedDate);
  }, []);

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  // subjects
  useEffect(() => {
    const getLibrary = async () => {
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("studentid", sessionData?.id);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/librarydata.json`,
          requestOptions
        );
        const result = await response.json();
        setSubjects(result?.Subjects[0]);
      } catch (error) {
        console.error(error);
      }
    };

    getLibrary();
  }, [apiUrl]);

  useEffect(() => {
    const fetchData = async () => {
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("class_id", sessionData?.class);
      formData.append("school_id", sessionData?.school_id);
      formData.append("session_id", sessionData?.session_id);
      formData.append("student_id", sessionData?.id);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/studentdairy.json`,
          requestOptions
        );
        const result = await response.json();
      } catch (error) {}
    };

    fetchData();
  }, [apiUrl]);

  const handliClick = () => {};

  const handleTextChange = (id, value) => {
    setTextareaVal(value)
    setSubjContent((prev) => ({
      ...prev,
      [id]: value,
    }));

    if (!subjectTextIDs.includes(id)) {
      setSubjectTextIDs((previous) => [...previous, id]);
    }
    if (value === "") {
      const filterItem = subjectTextIDs.filter((e) => {
        return e !== id;
      });
      setSubjectTextIDs(filterItem);
      setSubjContent((prev) => {
        const { [id]: removed, ...rest } = prev;
        return rest;
      });
    } else {
    }
  };

  const handleSave = async () => {
    const sessionData = await JSON.parse(localStorage.getItem("student"));
    const language = localStorage.getItem("lang");
    const rawData = {
      class_id: sessionData?.class,
      school_id: sessionData?.school_id,
      session_id: sessionData?.session_id,
      studentid: sessionData?.id,
      subject_id: subjectTextIDs, // Already an array, no need to JSON.stringify
      datedairy: todyDate,
      dairy_note: note,
      note: note,
      subject_content: subjectContent, // Already an array/object, no need to JSON.stringify
      lang: language,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Specify you're sending raw JSON
      },
      body: JSON.stringify(rawData),
    };

    try {
      const response = await fetch(
        `${apiUrl}/adddairydetail.json`,
        requestOptions
      );
      const result = await response.json();
      if (result?.status == 200) {
        textref.current.value = "";
        setNote('')
      }
      console.log(textref.current.value, "textref");
    } catch (error) {}
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <div id="main-content1" className="content_container">
          <ContentTitle name="Student Dairy" click={sidebarOpen} />
          <div className="card_board student-diary-card_board">
            <div className="content_card_title flex-view">
              Student Dairy
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
            <div className="row clearfix" style={{ padding: "0px 20px" }}>
              <div className="col-lg-12">
                <div className="card">
                  <div className="date-time-input-wrapper">
                    <div className="input-container">
                      <label>Select Date*</label>
                      <input
                        type="date"
                        value={todyDate}
                        onChange={(e) => setTodayDate(e.target.value)}
                      />
                    </div>
                    <button className="back-btn" onClick={handliClick}>
                      Submit
                    </button>
                  </div>
                  {/* student diary form wrapper */}
                  <div className="student-diary-form-wrapper">
                    <div className="title-and-date-row">
                      <h4>Student Diary</h4>
                      <h4>Date: {todyDate} </h4>
                    </div>
                    {subjects?.subjects_name?.split(",").map((val, index) => {
                      return (
                        <div className="student-diary-container">
                          <div className="student-diary-subject-container">
                            <p>{val}:</p>
                          </div>
                          <div className="student-diary-form-container">
                            <textarea
                              ref={textref}
                              placeholder="For Students..."
                              style={{ marginBottom: "8px" }}
                              onChange={(e) =>
                                handleTextChange(
                                  subjects?.subjects_ids?.split(",")[index],
                                  e.target.value
                                )
                              }
                            />
                            <textarea placeholder="For Teachers..." disabled />
                          </div>
                        </div>
                      );
                    })}
                    <div className="student-diary-container">
                      <div className="student-diary-subject-container">
                        <p>Dairy note:</p>
                      </div>
                      <div className="student-diary-form-container">
                        <textarea
                          style={{ height: "100px" }}
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="btn-container-save">
                      <button
                        className="back-btn"
                        style={{ marginTop: "20px" }}
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDiary;
