import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import Dashboard from "./admin/Dashboard";
import StudentProfile from "./admin/StudentProfile";
import StudentKnowledge from "./admin/StudentKnowledge";
import StudentViewProfile from "./admin/StudentViewProfile";
import Login from "./auth/login";
import { MessageProvider } from "./context/MessageContext";
import Announcement from "./admin/Announcement";
import Dropbox from "./admin/Dropbox";
import Subject from "./admin/Subject";
import { SubjectProvider } from "./context/SubjectIDContext";
import Library from "./admin/Library";
import LibrarySubject from "./admin/LibrarySubject";
import ViewLibraryContent from "./admin/ViewSubjectContent";
import DropboxView from "./admin/DropboxView";
import Quiz from "./admin/Quiz";
import Assignments from "./admin/Assignments";
import Exams from "./admin/Exams";
import Grades from "./admin/Grades";
import ViewQuize from "./admin/ViewQuize";
import SchoolContacts from "./admin/SchoolContacts";
import StudentMessageView from "./admin/StudentMessageView";
import AddSchoolMessage from "./admin/AddSchoolMessage";
import ViewExam from "./admin/ViewExam";
import StudyGuide from "./admin/StudyGuide";
import StudyAbroad from "./admin/StudyAbroad";
import LocalUniversity from "./admin/LocalUniversity";
import YouMeAcademyCommunity from "./admin/You-Me-Academy-community";
import Community from "./admin/Community";
import HowItWorks from "./admin/How-it-works";
import ViewHowItWorks from "./admin/View-Hoe-it-works";
import Scholarship from "./admin/Scholarship";
import Mentorship from "./admin/Mentorship";
import Internship from "./admin/Internship";
import IntensiveEnglish from "./admin/IntensiveEnglish";
import Leadership from "./admin/Leadership";
import NewTechnologies from "./admin/NewTechnologies";
import StateExam from "./admin/StateExam";
import IntensiveEnglishKindergarten from "./admin/IntensiveEnglish-Kindergarten";
// import IntensiveEnglishPrimary from "./admin/IntensiveEnglish-Primary";
// import IntensiveEnglishHightSchool from "./admin/IntensiveEnglish-HighSchool";
import CommunityView from "./admin/CommunityView";
import ViewInternship from "./admin/ViewInternship";
import ViewScholarship from "./admin/ViewScholarship";
import MentorshipView from "./admin/MentorshipView";
import NewTechnologiesView from "./admin/NewTechnologiesView";
import LeadershipView from "./admin/LeadershipView";
import YouMeLive from "./admin/You-Me-Live";
import YouMeLiveClassView from "./admin/You-Me-Live-ClassView";
import ViewExamInfo from "./admin/ViewExamInfo";
import StateExamInnerPage from "./admin/StateExamInnerPage";
import StateExamInnerPageView from "./admin/StateExamInnerPageView";
// import Test from "./admin/Test";
import GeneratePdf from "./components/GeneratePdf";
// import ViewExamPDF from "./admin/ExamPdf";
import StudyGuideView from "./admin/StudyGuideView";
import ViewIntensiveEnglishKindergarden from "./admin/ViewIntensiveEnglish-Kindergarden";
import ViewQuiz from "./admin/ViewQuize";
import ViewQuizeInfo from "./admin/ViewQuizInfo";
import ViewAssignment from "./admin/ViewAssignment";
import ViewAssignmentInfo from "./admin/ViewAssignmentInfo";
import SubjectGrades from "./admin/SubjectGrades";
import Calender from "../src/admin/Calender";
import Attendance from "./admin/Attendance";
import DayAttendance from "./admin/DayAttendance";
import SubjectAttendance from "./admin/SubjectAttendance";
import Events from "./admin/Events";
import MySchool from "./admin/MySchool";
import MySchoolTabView from "./admin/MySchoolTabView";
import MySchoolKnowledgeCenter from "./admin/MySchoolKnowledgeCenter";
import MySchoolKnowledgeCenterView from "./admin/MySchoolKnowledgeView";
import Discussions from "./admin/Discussions";
import MarketPlace from "./admin/MarketPlace";
import MyFees from "./admin/MyFees";
import Schedule from "./admin/Schedule";
import Canteen from "./admin/Canteen";
import CanteenFeatureVendor from "./admin/CanteenFeatureVendor";
import FoodHistory from "./admin/FoodHistory";
import GeneratePdfInvoice from "./components/GeneratePdfInvoice";
import VendorMenu from "./admin/VendorMenu";
import ViewCart from "./admin/ViewCart";
import TutoringCenter from "./admin/TutoringCenter";
import StudentDiary from "./admin/StudentDiary";
import TutoringContent from "./admin/TutoringCenterContent";
import TutoringContentView from "./admin/TutoringContentView";

function App() {
  return (
    <AuthProvider>
      <MessageProvider>
        <SubjectProvider>
          <Router>
            <Routes>
              <Route
                path="/dashboard/pdf/:exam_id/:subject/:id"
                element={<ProtectedRoute component={GeneratePdf} />}
              />
              <Route path="/" element={<PublicRoute component={Login} />} />
              <Route
                path="/dashboard"
                element={<ProtectedRoute component={Dashboard} />}
              />
              <Route
                path="/dashboard/tutoring_center"
                element={<ProtectedRoute component={TutoringCenter} />}
              />
              <Route
                path="/dashboard/tutoring_center/content/:subID/:tID/:cID"
                element={<ProtectedRoute component={TutoringContent} />}
              />
              <Route
                path="/dashboard/tutoring_center/content/view/:id/:tID"
                element={<ProtectedRoute component={TutoringContentView} />}
              />
              <Route
                path="/dashboard/schedule"
                element={<ProtectedRoute component={Schedule} />}
              />
              <Route
                path="/dashboard/canteen"
                element={<ProtectedRoute component={Canteen} />}
              />
              <Route
                path="/dashboard/canteen/foodhistory"
                element={<ProtectedRoute component={FoodHistory} />}
              />
              <Route
                path="/dashboard/canteen/vendoritems/:date/:time/:id"
                element={<ProtectedRoute component={VendorMenu} />}
              />
              <Route
                path="/dashboard/canteen/viewcart/:date/:time"
                element={<ProtectedRoute component={ViewCart} />}
              />
              <Route
                path="/dashboard/canteen/foodhistory/:orderID/downloadInvoicepdf"
                element={<ProtectedRoute component={GeneratePdfInvoice} />}
              />
              <Route
                path="/dashboard/canteen/featurevendor/:date/:time"
                element={<ProtectedRoute component={CanteenFeatureVendor} />}
              />
              <Route
                path="/dashboard/my-fees"
                element={<ProtectedRoute component={MyFees} />}
              />
              <Route
                path="/dashboard/marketplace"
                element={<ProtectedRoute component={MarketPlace} />}
              />
              <Route
                path="/dashboard/my_school"
                element={<ProtectedRoute component={MySchool} />}
              />
              <Route
                path="/dashboard/student_diary"
                element={<ProtectedRoute component={StudentDiary} />}
              />
              <Route
                path="/dashboard/my_school/code_of_conduct"
                element={<ProtectedRoute component={MySchoolTabView} />}
              />
              <Route
                path="/dashboard/my_school/knowledge_center"
                element={<ProtectedRoute component={MySchoolKnowledgeCenter} />}
              />
              <Route
                path="/dashboard/my_school/knowledge_center/view/:id"
                element={
                  <ProtectedRoute component={MySchoolKnowledgeCenterView} />
                }
              />
              <Route
                path="/dashboard/events"
                element={<ProtectedRoute component={Events} />}
              />
              <Route
                path="/dashboard/attendance"
                element={<ProtectedRoute component={Attendance} />}
              />
              <Route
                path="/dashboard/attendance/day"
                element={<ProtectedRoute component={DayAttendance} />}
              />
              <Route
                path="/dashboard/attendance/subject"
                element={<ProtectedRoute component={SubjectAttendance} />}
              />
              <Route
                path="/dashboard/student-profile"
                element={<ProtectedRoute component={StudentProfile} />}
              />
              <Route
                path="/dashboard/student-knowledge"
                element={<ProtectedRoute component={StudentKnowledge} />}
              />
              <Route
                path="/dashboard/student-knowledge/community"
                element={<ProtectedRoute component={YouMeAcademyCommunity} />}
              />
              <Route
                path="/dashboard/student-knowledge/how-it-works"
                element={<ProtectedRoute component={HowItWorks} />}
              />
              <Route
                path="/dashboard/student-knowledge/how-it-works/view/:id"
                element={<ProtectedRoute component={ViewHowItWorks} />}
              />
              <Route
                path="/dashboard/student-knowledge/scholarship"
                element={<ProtectedRoute component={Scholarship} />}
              />
              <Route
                path="/dashboard/student-knowledge/mentorship"
                element={<ProtectedRoute component={Mentorship} />}
              />
              <Route
                path="/dashboard/student-knowledge/mentorship/view/:id"
                element={<ProtectedRoute component={MentorshipView} />}
              />
              <Route
                path="/dashboard/student-knowledge/internship"
                element={<ProtectedRoute component={Internship} />}
              />
              <Route
                path="/dashboard/student-knowledge/intensive_english"
                element={<ProtectedRoute component={IntensiveEnglish} />}
              />
              <Route
                path="/dashboard/student-knowledge/leadership"
                element={<ProtectedRoute component={Leadership} />}
              />
              <Route
                path="/dashboard/student-knowledge/leadership/view/:id"
                element={<ProtectedRoute component={LeadershipView} />}
              />
              <Route
                path="/dashboard/student-knowledge/new_technologies"
                element={<ProtectedRoute component={NewTechnologies} />}
              />
              <Route
                path="/dashboard/student-knowledge/new_technologies/view/:id"
                element={<ProtectedRoute component={NewTechnologiesView} />}
              />
              <Route
                path="/dashboard/student-knowledge/state_exam"
                element={<ProtectedRoute component={StateExam} />}
              />
              <Route
                path="/dashboard/state-exam/:name"
                element={<ProtectedRoute component={StateExamInnerPage} />}
              />
              <Route
                path="/dashboard/state-exam/:name/:id"
                element={<ProtectedRoute component={StateExamInnerPageView} />}
              />
              <Route
                path="/dashboard/student-knowledge/community/:name"
                element={<ProtectedRoute component={Community} />}
              />
              <Route
                path="/dashboard/student-knowledge/community/view/:id"
                element={<ProtectedRoute component={CommunityView} />}
              />
              <Route
                path="/dashboard/student-knowledge/internship/view/:id"
                element={<ProtectedRoute component={ViewInternship} />}
              />
              <Route
                path="/dashboard/student-knowledge/scholarship/view/:id"
                element={<ProtectedRoute component={ViewScholarship} />}
              />
              <Route
                path="/dashboard/student-knowledge/intensive_english/:id"
                element={
                  <ProtectedRoute component={IntensiveEnglishKindergarten} />
                }
              />
              {/* <Route
                path="/dashboard/student-knowledge/intensive_english/primary"
                element={<ProtectedRoute component={IntensiveEnglishPrimary} />}
              />
              <Route
                path="/dashboard/student-knowledge/intensive_english/high-school"
                element={
                  <ProtectedRoute component={IntensiveEnglishHightSchool} />
                }
              /> */}
              <Route
                path="/dashboard/study-abroad"
                element={<ProtectedRoute component={StudyAbroad} />}
              />
              <Route
                path="/dashboard/local-university"
                element={<ProtectedRoute component={LocalUniversity} />}
              />
              <Route
                path="/dashboard/student-view-profile"
                element={<ProtectedRoute component={StudentViewProfile} />}
              />
              <Route
                path="/dashboard/announcement"
                element={<ProtectedRoute component={Announcement} />}
              />
              <Route
                path="/dashboard/dropbox"
                element={<ProtectedRoute component={Dropbox} />}
              />
              <Route
                path="/dashboard/dropbox/:dropboxId"
                element={<DropboxView />}
              />
              <Route
                path="/dashboard/subject"
                element={<ProtectedRoute component={Subject} />}
              />
              <Route
                path="/dashboard/subject/quiz/:sutudentSub/:studentId"
                element={<ProtectedRoute component={Quiz} />}
              />
              <Route
                path="/dashboard/subject/quiz/viewQuiz/:studentId/:quizId"
                element={<ProtectedRoute component={ViewQuize} />}
              />
              <Route
                path="/dashboard/subject/assignments/:sutudentSub/:studentId"
                element={<ProtectedRoute component={Assignments} />}
              />
              <Route
                path="/dashboard/subject/discussions/:id"
                element={<ProtectedRoute component={Discussions} />}
              />
              <Route
                path="/dashboard/subject/exams/:sutudentSub/:studentId"
                element={<ProtectedRoute component={Exams} />}
              />
              <Route
                path="/dashboard/subject/exams/view_exam/:submitExamId/:examId"
                element={<ProtectedRoute component={ViewExam} />}
              />
              <Route
                path="/dashboard/subject/exams/view_Exam/info/:studentId/:examId"
                element={<ProtectedRoute component={ViewExamInfo} />}
              />
              <Route
                path="/dashboard/library"
                element={<ProtectedRoute component={Library} />}
              />
              <Route
                path="/dashboard/grades"
                element={<ProtectedRoute component={Grades} />}
              />
              <Route
                path="/dashboard/subject/grades/:sutudentSub/:studentId"
                element={<ProtectedRoute component={SubjectGrades} />}
              />
              <Route
                path="/dashboard/study-guide/:sutudentSub/:studentId"
                element={<ProtectedRoute component={StudyGuide} />}
              />
              <Route
                path="/dashboard/study-guide/view/:id"
                element={<ProtectedRoute component={StudyGuideView} />}
              />
              <Route
                path="/dashboard/library-subject/:id"
                element={<ProtectedRoute component={LibrarySubject} />}
              />
              <Route
                path="/dashboard/library-content/:id"
                element={<ProtectedRoute component={ViewLibraryContent} />}
              />
              <Route
                path="/dashboard/school_contacts"
                element={<ProtectedRoute component={SchoolContacts} />}
              />
              <Route
                path="/dashboard/school_contacts/message/view/:messageId"
                element={<ProtectedRoute component={StudentMessageView} />}
              />
              <Route
                path="/dashboard/school_contacts/message/add"
                element={<ProtectedRoute component={AddSchoolMessage} />}
              />
              <Route
                path="/dashboard/you-me-live"
                element={<ProtectedRoute component={YouMeLive} />}
              />
              <Route
                path="/dashboard/subject/quiz/view_quiz/info/:studentId/:examId"
                element={<ProtectedRoute component={ViewQuizeInfo} />}
              />
              <Route
                path="/dashboard/subject/assignment/view_assignment/:submitExamId/:examId"
                element={<ProtectedRoute component={ViewAssignment} />}
              />
              <Route
                path="/dashboard/subject/quiz/view_quiz/:submitExamId/:examId"
                element={<ProtectedRoute component={ViewQuiz} />}
              />
              <Route
                path="/dashboard/subject/assignment/view_assignment/info/:studentId/:examId"
                element={<ProtectedRoute component={ViewAssignmentInfo} />}
              />
              <Route
                path="/dashboard/you-me-live/class/:id"
                element={<ProtectedRoute component={YouMeLiveClassView} />}
              />
              <Route
                path="/dashboard/student-knowledge/intensive_english/view/:name/:id"
                element={
                  <ProtectedRoute
                    component={ViewIntensiveEnglishKindergarden}
                  />
                }
              />
              <Route
                path="/dashboard/calender"
                element={<ProtectedRoute component={Calender} />}
              />
            </Routes>
          </Router>
        </SubjectProvider>
      </MessageProvider>
    </AuthProvider>
  );
}

export default App;
