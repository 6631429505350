import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";

const VendorMenu = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { date, time } = useParams();
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [cartBtnShow, setCartBtnShow] = useState([]);
  const [count, setCount] = useState(0);
  const [modalOoen, setModalOpen] = useState(false);
  const [foodList, setFoodList] = useState([]);
  const [foodDetail, setFoodDetail] = useState("");
  const [indexCount, setIndexCount] = useState([]);

  const convertDateToTimestamp = (dateString) => {
    // Create a new Date object in UTC with a specific time (e.g., 9 AM UTC)
    const date = new Date(dateString + "T09:00:00Z");

    // Convert the date to a timestamp (in seconds)
    const timestamp = Math.floor(date.getTime() / 1000);

    return timestamp;
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  const handleModalOpen = (detail) => {
    setModalOpen(true);
    setFoodDetail(detail);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // food list api
  useEffect(() => {
    const getFoodList = async () => {
      const language = localStorage.getItem("lang");
      const studentData = JSON.parse(localStorage.getItem("student"));
      const formData = new FormData();
      formData.append("student_id", studentData?.id);
      formData.append("school_id", studentData?.school_id);
      formData.append("class_id", studentData?.class);
      formData.append("food_serveid", "822");
      formData.append("seldate", date);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${apiUrl}/vendorfood.json`,
          requestOptions
        );
        const result = await response.json();
        setFoodList(result);
      } catch (error) {
        console.error(error);
      }
    };

    getFoodList();
  }, [apiUrl]);

  //   add to cart api
  const addItems = async (index, val) => {
    // Show the cart button for the clicked item (specific index)
    setCartBtnShow((prevShow) => {
      const updatedShow = [...prevShow];
      updatedShow[index] = true; // Show the button for this index
      return updatedShow;
    });

    // Increase the count for the specific index
    setIndexCount((prevCounts) => {
      const updatedCounts = [...prevCounts];
      updatedCounts[index] = (updatedCounts[index] || 0) + 1; // Increment count for the index
      return updatedCounts;
    });

    const language = localStorage.getItem("lang");
    const studentData = JSON.parse(localStorage.getItem("student"));
    const timestamp = await convertDateToTimestamp(date);
    const formData = new FormData();
    formData.append("student_id", studentData?.id);
    formData.append("session_id", studentData?.session_id);
    formData.append("school_id", studentData?.school_id);
    formData.append("class_id", studentData?.class);
    formData.append("food_id", val?.food_id);
    formData.append("vendor_id", foodList?.vendorfood_details[0]?.vendor_id);
    formData.append("fp", val?.price);
    formData.append("qnty", (indexCount[index] || 0) + 1);
    formData.append("stime", time);
    formData.append("sdate", timestamp);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(`${apiUrl}/cartdata.json`, requestOptions);
      const result = await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  //   remove to cart api
  const removeItem = async (index) => {
    const updatedCounts = [...indexCount]; // Copy the previous array
    if (updatedCounts[index] > 0) {
      // Increase the count for the specific index
      setIndexCount((prevCounts) => {
        const updatedCounts = [...prevCounts];
        updatedCounts[index] = updatedCounts[index] - 1; // Increment count for the index
        return updatedCounts;
      });
    } else {
      // Show the cart button for the clicked item (specific index)
      setCartBtnShow((prevShow) => {
        const updatedShow = [...prevShow];
        updatedShow[index] = false; // Show the button for this index
        return updatedShow;
      });
    }

    const language = localStorage.getItem("lang");
    const studentData = JSON.parse(localStorage.getItem("student"));
    const timestamp = convertDateToTimestamp(date);
    const formData = new FormData();
    formData.append("student_id", studentData?.id);
    formData.append("session_id", studentData?.session_id);
    formData.append("school_id", studentData?.school_id);
    formData.append("class_id", studentData?.class);
    formData.append("food_id", "42");
    formData.append("vendor_id", "13");
    formData.append("fp", "3");
    formData.append("qnty", indexCount[index] - 1);
    formData.append("stime", time);
    formData.append("sdate", timestamp);
    formData.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const response = await fetch(`${apiUrl}/cartdata.json`, requestOptions);
      const result = await response.json();
    } catch (error) {
      console.error(error);
    }
  };

  console.log(indexCount, "indexCount");

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />

      <div className={`main containerFull`}>
        <ContentTitle name="Canteen" click={sidebarOpen} />
        <div className="card_board">
          <div
            className="content_card_title flex-view"
            style={{
              fontWeight: "lighter",
              fontSize: "25px",
              color: "rgb(75, 73, 73)",
            }}
          >
            Menu principal
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              <b>
                Date: {date} Timings: {time}
              </b>
            </div>
            <div className="selectboxes">
              <button
                className="contact-us-btn"
                onClick={() => navigate("/dashboard")}
              >
                Back To Dashboard
              </button>
              <span
                style={{ marginRight: "10px", marginBottom: "10px" }}
              ></span>
              <button className="back-btn" onClick={goBack}>
                Back
              </button>
            </div>
          </div>
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <div style={{ padding: "0px 20px" }}>
              <h2
                style={{
                  fontWeight: "lighter",
                  fontSize: "20px",
                  color: "rgb(75, 73, 73)",
                }}
              >
                Food List
              </h2>
              <div className="food-item-wrapper">
                {foodList?.food_details?.map((val, index) => {
                  return (
                    <div className="menu-item-container" key={val?.food_id}>
                      <div className="img-container">
                        <img
                          src={`https://you-me-globaleducation.org/school/c_food/${val?.food_item?.food_img}`}
                          alt={val?.food_item?.food_img}
                        />
                      </div>
                      <div className="content-container">
                        <div className="text-container">
                          <div>
                            <h4>{val?.food_item?.food_name}</h4>
                            <h4>${val?.price}</h4>
                          </div>
                          <i
                            className="bi bi-eye"
                            onClick={() =>
                              handleModalOpen(val?.food_item?.details)
                            }
                          ></i>
                        </div>
                        <div className="btn-container">
                          {cartBtnShow[index] && indexCount[index] > 0 ? (
                            <>
                              <button
                                className="btn-2"
                                style={{
                                  width: "80px",
                                  justifyContent: "center",
                                }}
                              >
                                <i
                                  className="bi bi-cart4"
                                  style={{ marginRight: "0" }}
                                ></i>
                              </button>
                              <div className="quantity-wrapper">
                                <span onClick={() => removeItem(index, val)}>
                                  -
                                </span>
                                {indexCount[index] === "undefined"
                                  ? 0
                                  : indexCount[index]}
                                <span onClick={() => addItems(index, val)}>
                                  +
                                </span>
                              </div>
                            </>
                          ) : (
                            <button
                              className="btn-1"
                              onClick={() => addItems(index, val)}
                            >
                              <i className="bi bi-cart4"></i>
                              Add To Cart
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="greenline">
        <button className="back-btn">
          {count} Items(s) in cart / Total Price: ${3 * count}
        </button>
        <button
          className="back-btn"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/dashboard/canteen/viewcart/${date}/${time}`);
          }}
        >
          Go To Cart
        </button>
      </div>

      <div
        className={`student-Modal-container`}
        style={{ visibility: `${modalOoen ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box modal-announcement ${
            modalOoen ? "transformOn" : "transformOff"
          }`}
          style={{ maxWidth: "500px", minWidth: "250px" }}
        >
          <div className="custom-modal-title">
            Food details
            <i
              className="bi bi-x-lg"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>

          <p style={{ color: "gray", fontSize: "13px" }}>{foodDetail}</p>
        </div>
      </div>
    </div>
  );
};

export default VendorMenu;
