import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Pagination from "../components/Pagination";
import "../css/style.css";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import CryptoJS from "crypto-js";
import { useMessages } from "../context/MessageContext";
import { Link } from "react-router-dom";

const MarketPlace = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const reactApiUrl = process.env.REACT_APP_API_MAIN_URL;
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [dealeridFilter, setDealeridFilter] = useState("");
  const [dealerid, setDealerid] = useState([]);
  const [productFilter, setProductFilter] = useState("");
  const [products, setProducts] = useState([]);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [catdealerid, setCatDealerId] = useState("");
  const [originalData, setOriginalData] = useState([]); // Original fetched data
  const { showSuccess, showError, clearMessages } = useMessages();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    quantity: "",
    message: "",
  });
  const [dataId, setDataId] = useState(null);
  const [uniName, setUniName] = useState("");
  const [uniPic, setUniPic] = useState("");

  // form data api
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const language = localStorage.getItem("lang");
      const offset = (currentPage - 1) * limit;
      const formData = new FormData();
      formData.append("lang", language);
      formData.append("categoryid", categoryFilter);
      formData.append("dealerid", dealeridFilter);
      formData.append("productid", productFilter);
      formData.append("offset", 1);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${reactApiUrl}/marketplace.json`,
          requestOptions
        );
        const result = await response.json();
        setData(result?.products);
        setOriginalData(result?.products);
        setCatDealerId(result?.dealers?.id);
        // setCategoryFilter(result?.categories?.id)
        setTotalPages(Math.ceil(result.total_records / limit));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [reactApiUrl, productFilter, currentPage, limit]);

  // category api
  useEffect(() => {
    const getCategory = async () => {
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("dealerid", catdealerid);
      formData.append("lang", language);
      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${reactApiUrl}/getcategory.json`,
          requestOptions
        );
        const result = await response.json();
        // Parse the HTML string to extract the options
        const parser = new DOMParser();
        const doc = parser.parseFromString(result.list_data, "text/html");
        const optionElements = doc.querySelectorAll("option");

        // Convert NodeList to array of objects
        const parsedOptions = Array.from(optionElements).map((option) => ({
          value: option.value,
          label: option.textContent,
        }));
        setCategories(parsedOptions);
      } catch (error) {
        console.error(error);
      }
    };

    getCategory();
  }, [reactApiUrl, catdealerid]);

  console.log(categoryFilter, "categoryFilter");

  // search bar filter
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    const filteredData = originalData.filter((item) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      return (
        (item.catname?.toLowerCase() || "").includes(lowerCaseSearchTerm) ||
        (item.dealname?.toLowerCase() || "").includes(lowerCaseSearchTerm) ||
        (item.price?.toString() || "")
          .toLowerCase()
          .includes(lowerCaseSearchTerm) ||
        (item.product_name?.toLowerCase() || "").includes(
          lowerCaseSearchTerm
        ) ||
        (item.quantity != null &&
          item.quantity.toString().includes(lowerCaseSearchTerm))
      );
    });
    setData(filteredData);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const goBack = () => {
    window.history.back();
  };

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  // dealers api
  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
    const getDealer = async () => {
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("catid", event.target.value);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${reactApiUrl}/getdealers.json`,
          requestOptions
        );
        const result = await response.json();
        // Parse the HTML string to extract the options
        const parser = new DOMParser();
        const doc = parser.parseFromString(
          result.list_data?.dataa,
          "text/html"
        );
        const optionElements = doc.querySelectorAll("option");

        // Convert NodeList to array of objects
        const parsedOptions = Array.from(optionElements).map((option) => ({
          value: option.value,
          label: option.textContent,
        }));
        setDealerid(parsedOptions);
      } catch (error) {
        console.error(error);
      }
    };

    getDealer();
  };

  console.log(dealeridFilter, "dealeridFilter");

  // products api
  const handleDealeridChange = (event) => {
    setDealeridFilter(event.target.value);
    const getProducts = async () => {
      const language = localStorage.getItem("lang");
      const formData = new FormData();
      formData.append("categoryid", categoryFilter);
      formData.append("dealerid", event.target.value);
      formData.append("lang", language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };
      try {
        const response = await fetch(
          `${reactApiUrl}/getproducts.json`,
          requestOptions
        );
        const result = await response.json();
        // Parse the HTML string to extract the options
        const parser = new DOMParser();
        const doc = parser.parseFromString(result.list_data?.list, "text/html");
        const optionElements = doc.querySelectorAll("option");

        // Convert NodeList to array of objects
        const parsedOptions = Array.from(optionElements).map((option) => ({
          value: option.value,
          label: option.textContent,
        }));
        setProducts(parsedOptions);
      } catch (error) {
        console.error(error);
      }
    };

    getProducts();
  };

  console.log(productFilter, "productFilter");

  const handleProductsChange = (event) => {
    setProductFilter(event.target.value);
  };

  const handleShowModal = (val) => {
    setDataId(val?.id);
    setUniName(val?.product_name);
    setUniPic(val?.product_image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      contact: "",
      quantity: "",
      message: "",
    });
    setShowModal(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // purchase form api
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const language = localStorage.getItem("lang");
    const formDatas = new FormData();
    formDatas.append("name", formData?.name);
    formDatas.append("email", formData?.email);
    formDatas.append("prodquantity", formData?.quantity);
    formDatas.append("number", formData?.contact);
    formDatas.append("desc", formData?.message);
    formDatas.append("prodcid", dataId);
    formDatas.append("lang", language);

    const requestOptions = {
      method: "POST",
      body: formDatas,
    };
    try {
      const response = await fetch(
        `${reactApiUrl}/purchaseform.json`,
        requestOptions
      );
      const result = await response.json();
      if (response.ok) {
        setError("");
        showSuccess(result?.message);
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      } else {
        setError(result?.message);
      }
    } catch (error) {
      console.error(error);
      setError(error?.message);
    } finally {
      setLoading(false);
      setTimeout(() => {
        clearMessages();
      }, 3000);
    }
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="You-Me Market Place" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            You-Me Market Place
            <button className="back-btn" onClick={goBack}>
              Back
            </button>
          </div>
          <div className="row clearfix" style={{ padding: "0px 20px" }}>
            <div className="col-lg-12">
              <div className="card">
                <div className="grade-selectboxes">
                  <label>
                    <p>Choose Category</p>
                    <select
                      className="form-control subj_s select2-hidden-accessible"
                      id="subjfilter"
                      onChange={handleCategoryChange}
                      value={categoryFilter}
                    >
                      <option selected>Choose Category</option>
                      {categories.map((category) => (
                        <option key={category.value} value={category.value}>
                          {category.label}
                        </option>
                      ))}
                    </select>
                  </label>
                  <label>
                    <p>Choose Dealer</p>
                    <select
                      className="form-control community_filter select2-hidden-accessible"
                      id="comm_filter"
                      onChange={handleDealeridChange}
                      value={dealeridFilter}
                    >
                      <option selected>Choose Dealer</option>
                      {dealerid.map((category) => (
                        <option key={category.value} value={category.value}>
                          {category.label}
                        </option>
                      ))}
                    </select>
                  </label>

                  <label>
                    <p>Choose Products</p>
                    <select
                      className="form-control community_filter select2-hidden-accessible"
                      id="comm_filter"
                      onChange={handleProductsChange}
                      value={productFilter}
                    >
                      <option selected>Choose Products</option>
                      {products.map((category) => (
                        <option key={category.value} value={category.value}>
                          {category.label}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="entries-and-search-container">
                  <div className="entries">
                    Show
                    <select
                      className="form-control"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                      <option value={50}>50</option>
                    </select>
                    entries
                  </div>
                  <div className="entries">
                    Search:
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="table-container">
                  <div className="table-responsive">
                    {loading ? (
                      <ThreeDots
                        visible={true}
                        height="80"
                        width="80"
                        color="#8e008e"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        wrapperClass=""
                      />
                    ) : (
                      // <p>Loading...</p>
                      <table
                        className="table table-hover js-basic-example dataTable table-custom table-striped m-b-0 c_list default_pageitem notification_table no-footer"
                        id="notification_table"
                        data-page-length="50"
                        role="grid"
                        aria-describedby="notification_table_info"
                      >
                        <thead className="thead-dark">
                          <tr role="row">
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Course Name: activate to sort column ascending"
                            >
                              Product Image
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Type: activate to sort column ascending"
                            >
                              Product Name
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Periode: activate to sort column ascending"
                            >
                              Seller
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Marks: activate to sort column ascending"
                            >
                              Category
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Grades: activate to sort column ascending"
                            >
                              Price
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Grades: activate to sort column ascending"
                            >
                              Quantity
                            </th>
                            <th
                              className="sorting"
                              tabIndex="0"
                              aria-controls="notification_table"
                              rowSpan="1"
                              colSpan="1"
                              aria-label="Grades: activate to sort column ascending"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody id="notificationbody">
                          {data?.map((val, index) => {
                            return (
                              <>
                                <tr role="row" className="odd" key={index}>
                                  <td>
                                    <img
                                      src={`https://you-me-globaleducation.org/school/productimages/${val?.product_image}`}
                                      alt="product-iamge"
                                    />
                                  </td>
                                  <td>{val?.product_name}</td>
                                  <td>{val?.dealname}</td>
                                  <td>{val?.catname}</td>
                                  <td>{`$${val?.price}`}</td>
                                  <td>{val?.quantity}</td>
                                  <td>
                                    <button
                                      onClick={() => handleShowModal(val)}
                                      className="btn btn-sm btn-outline-secondary"
                                      style={{
                                        cursor: "pointer",
                                        background: "#8e008e",
                                        color: "white",
                                        border: "none",
                                      }}
                                    >
                                      Purchase
                                    </button>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                <div className="pagination-container">
                  <p>
                    Show 1 to {limit} of {data?.length} entries
                  </p>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`student-Modal-container`}
        style={{ visibility: `${showModal ? "visible" : "hidden"}` }}
      >
        <div
          className={`modal-box ${showModal ? "transformOn" : "transformOff"}`}
          style={{ maxWidth: "50%", minWidth: "300px" }}
        >
          <div className="custom-modal-title">
            <span style={{ display: "flex", alignItems: "center" }}>
              {" "}
              Purchase -
              <div className="modal-img-container">
                <img
                  src={`https://you-me-globaleducation.org/school/productimages/${uniPic}`}
                  alt="product image"
                />
              </div>
              {uniName}
            </span>{" "}
            <i
              className="bi bi-x-lg"
              onClick={handleCloseModal}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
          <div className="form-container">
            <form
              onSubmit={(e) => handleSubmit(e)}
              encType="multipart/form-data"
              className="contact-modal"
            >
              {/* first row */}
              <div className="form-row" style={{ marginBottom: "10px" }}>
                <div
                  className="form-group form-control"
                  style={{ width: "45%" }}
                >
                  <label>Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    required
                    placeholder="Enter Name"
                    onChange={handleChange}
                  />
                </div>
                <div
                  className="form-group form-control"
                  style={{ width: "45%" }}
                >
                  <label>Email*</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    required
                    value={formData.email}
                    placeholder="Enter Email"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* second row */}
              <div className="form-row" style={{ marginBottom: "10px" }}>
                <div
                  className="form-group form-control"
                  style={{ width: "45%" }}
                >
                  <label>Contact No.*</label>
                  <input
                    type="number"
                    className="form-control"
                    name="contact"
                    required
                    value={formData.contact}
                    placeholder="Enter Contact No."
                    onChange={handleChange}
                  />
                </div>
                <div
                  className="form-group form-control"
                  style={{ width: "45%" }}
                >
                  <label>Quantity*</label>
                  <input
                    type="number"
                    className="form-control"
                    name="quantity"
                    required
                    value={formData.quantity}
                    placeholder="Enter Quantity"
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* third row */}
              <div className="form-row" style={{ marginBottom: "10px" }}>
                <div
                  className="form-group form-control"
                  style={{ width: "100%" }}
                >
                  <label>Messages*</label>
                  <textarea
                    required
                    placeholder="Enter Description"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* btn-row */}
              <div className="btn-container">
                <button
                  type="submit"
                  id="editstdntprfbtn"
                  className="btn btn-primary form-update"
                >
                  Send
                </button>
                <span style={{ margin: "0px 10px" }}></span>
                <button
                  id="editstdntprfbtn"
                  className="btn btn-primary form-update"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
              <p style={{ color: "red" }}>{error}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketPlace;
