import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../css/slider.css";

const Slider = ({datass, children}) => {
  console.log(datass, 'datass')
  return (
    <div className="slider-wrapper">
      {children}
    </div>
  );
}

export default Slider;

