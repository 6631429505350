import React, { useContext, useEffect, useState } from "react";
import profileModal from "../images/logo.png";
import { Link } from "react-router-dom";
import { useMessages } from "../context/MessageContext";
import { ThreeDots } from "react-loader-spinner";
import { SubjectContext } from "../context/SubjectIDContext";

export const StudentModal = ({ classtransform, closeModal, data }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [teacherDetail, setTeacherDetail] = useState([]);
  const apiUrl = process.env.REACT_APP_API_MAIN_URL;
  const { subjectId } = useContext(SubjectContext);

  useEffect(() => {
    console.log(subjectId, "subjectId");
    const fetchData = async () => {
      setLoading(true);
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      const language = localStorage.getItem('lang');
      const formData = new FormData();
      formData.append("class_id", sessionData?.class);
      formData.append(
        "subject_id",
        (await data?.id) ? data?.id : subjectId?.id
      );
      formData.append("school_id", sessionData?.school_id);
      console.log(sessionData?.class, data.id, sessionData?.school_id);
      formData.append('lang', language);

      const requestOptions = {
        method: "POST",
        body: formData,
      };

      try {
        const response = await fetch(
          `${apiUrl}/getteacherdetail.json`,
          requestOptions
        );
        const result = await response.json();
        setTeacherDetail(result?.teacher_details);
      } catch (error) {
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl, data, data?.id]);

  return (
    <div
      className={`student-Modal-container`}
      style={{
        visibility: `${
          classtransform === "transformOn" ? "visible" : "hidden"
        }`,
      }}
    >
      <div className={`modal-box ${classtransform}`}>
        <div className="modal-title">
          Subject Details{" "}
          <i
            className="bi bi-x-lg"
            onClick={closeModal}
            style={{ cursor: "pointer" }}
          ></i>
        </div>
        <div className="modal-profile-container">
          <div className="top-btn-container">
            <button className="top-btn" style={{ marginTop: "0px" }}>
              {data?.subject_name}
            </button>
            <button
              className="top-btn"
              style={{ marginLeft: "10px", marginTop: "0px" }}
            >
              Attendance
            </button>
          </div>
          <h5>Teacher Details</h5>
          {loading ? (
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#8e008e"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              wrapperClass=""
            />
          ) : (
            <div className="teacher-detail-container">
              {teacherDetail?.map((val, ind) => {
                console.log(
                  data?.subject_name.split(" ").join("_"),
                  "subject name"
                );
                return (
                  <>
                    <div className="teacher-profile" key={ind}>
                      <img
                        src={`https://you-me-globaleducation.org/school/webroot/img/${val?.pict}`}
                        alt="teacher img"
                      />
                    </div>
                    <div className="teacher-profile-data">
                      <p>
                        Name: {val?.f_name} {val?.l_name}
                      </p>
                      <p>Email: {val?.email}</p>
                      <p>Contact: {val?.mobile_no}</p>
                      <p>Qualification: {val?.quali}</p>
                    </div>
                  </>
                );
              })}

              {error}
            </div>
          )}
        </div>
        <div className="bottom-btn-container">
          <Link
            to={`/dashboard/library-subject/${data.id}`}
            className="top-btn"
          >
            Library
          </Link>
          <Link
            to={`/dashboard/subject/discussions/${data.id}`}
            className="top-btn"
            style={{ marginLeft: "10px" }}
          >
            Discussion
          </Link>
          <Link
            to={`/dashboard/subject/assignments/${data?.subject_name
              ?.split(" ")
              .join("_")}/${data.id}`}
            className="top-btn"
            style={{ marginLeft: "10px" }}
          >
            Assignments
          </Link>
          <Link
            to={`/dashboard/subject/exams/${data?.subject_name
              ?.split(" ")
              .join("_")}/${data.id}`}
            className="top-btn"
            style={{ marginLeft: "10px" }}
          >
            Exams
          </Link>
          <Link
            to={`/dashboard/study-guide/${data?.subject_name
              ?.split(" ")
              .join("_")}/${data.id}`}
            className="top-btn"
            style={{ marginLeft: "10px" }}
          >
            Study Guide
          </Link>
          <Link
            to={`/dashboard/subject/quiz/${data?.subject_name
              ?.split(" ")
              .join("_")}/${data.id}`}
            className="top-btn"
            style={{ marginLeft: "10px" }}
          >
            Quiz
          </Link>
          <Link
            to={`/dashboard/subject/grades/${data?.subject_name
              ?.split(" ")
              .join("_")}/${data.id}`}
            className="top-btn"
            style={{ marginLeft: "10px" }}
          >
            Grades
          </Link>
        </div>
      </div>
    </div>
  );
};
