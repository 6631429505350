import React, { useEffect, useState } from "react";
import { Link, json, useParams } from "react-router-dom";
import Header from "../components/Header";
import "../css/style.css";
import Sidebar from "../components/Sidebar";
import ContentTitle from "../components/ContentTitle";
import { ThreeDots } from "react-loader-spinner";
import dummyImage from "../images/logo.png";

const StudyGuide = () => {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [library, setLibrary] = useState([]);
  const [subject, setSubject] = useState("");
  const [classname, setClassname] = useState("");
  const [communityFilter, setCommunityFilter] = useState("newest");
  const apiUrl = process.env.REACT_APP_API_NODE_URL;
  const [studyGuide, setStudyGuide] = useState([]);
  const { studentId } = useParams();
  const { sutudentSub } = useParams();
  const SERVERIMGURL = "https://you-me-globaleducation.org/school/img";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [studentId, sutudentSub]);

  const handleCommunityChange = (event) => {
    setCommunityFilter(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const sessionData = await JSON.parse(localStorage.getItem("student"));
      setLoading(true);
      const language = localStorage.getItem("lang");

      const requestOptions = {
        method: "GET",
      };
      try {
        const response = await fetch(
          `${apiUrl}/quiz/getQuiz?school_id=${sessionData?.school_id}&subject_id=${studentId}&class_id=${sessionData?.class}&student_id=${sessionData?.id}&type=Study Guide&session_id=${sessionData?.session_id}&lang=${language}`,
          requestOptions
        );
        const result = await response.json();
        console.log(result, "resultssssssssssss");
        setStudyGuide(result);
      } catch (error) {
        console.error(error);
        setError(error.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [apiUrl]);

  console.log(subject, library, classname);

  const sidebarOpen = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <div className="dashboard">
      <Header click={sidebarOpen} />
      <Sidebar addClass={`${sidebarToggle ? "sideShow" : "sideHide"}`} />
      <div
        className={`main ${sidebarToggle ? "containerHalf" : "containerFull"}`}
      >
        <ContentTitle name="You-Me Academy" click={sidebarOpen} />
        <div className="card_board">
          <div className="content_card_title flex-view">
            Study Guide{" "}
            <button className="back-btn" onClick={goBack}>
              Back
            </button>{" "}
          </div>
          <div className="form-container">
            <div className="drobbox-body">
              {loading ? (
                <ThreeDots
                  visible={true}
                  height="80"
                  width="80"
                  color="#8e008e"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  wrapperClass=""
                />
              ) : (
                // <p>Loading...</p>
                <div className="data-body">
                  <div className="data-body-row" id="viewcommunity">
                    {studyGuide?.map((work) => {
                      console.log(work.id, "idddddddddddd");
                      return (
                        <div
                          className="col-sm-2 col_img"
                          key={work.id}
                          style={{ marginBottom: "10px" }}
                        >
                          <Link
                            to={`/dashboard/study-guide/view/${work.id}`}
                            className="viewknow"
                          >
                            {work?.image && work.image !== "" ? (
                              <img
                                src={`${SERVERIMGURL}/${work.image}`}
                                alt="Dropbox Icon"
                              />
                            ) : (
                              <img src={dummyImage} alt="Dropbox Icon" />
                            )}
                            <div className="set_icon">
                              <i className="bi bi-camera-video-fill"></i>
                            </div>
                          </Link>
                          <p className="title" style={{ color: "#000" }}>
                            <b>Titre</b>: {work.title}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {/* {!howItWorks?.length ? <h3 style={{ color: "gray", margin: "0" }}>Data Not Found</h3> : ""} */}
              <form
                method="post"
                acceptCharset="utf-8"
                action="/school/Dropbox/"
                style={{ display: "none" }}
              >
                <div>
                  <input type="hidden" name="_method" value="POST" />
                  <input
                    type="hidden"
                    name="_csrfToken"
                    autoComplete="off"
                    value="fd82b978dfb069d344e73b0d4e9dca020ee9d23fbca43988689186f03e216fc58422d41d5d040c943c9761c1bcb0be38cb915d43025ce94bebffabf8685a6482"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyGuide;
